import React from 'react'

export default function Gigs() {
  return (
    <main>
      <article>
        <p>No upcoming gigs just yet - more to come in 2024!</p>
      </article>
    </main>
  )
}
