import React from 'react'

import shirt_a from '../assets/shirt_a.png'

export default function Merch() {
  return (
    <main>
        <article>
            <p>BLACK AND WHITE SHIRTS AVAILABLE - 7 IN STOCK - VARIOUS SIZES AVAILABLE</p>
            <img style={{width: '75vw', paddingRight: '50px'}} src={shirt_a} alt=''></img>
        </article>
    </main>
  )
}
