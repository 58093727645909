import React from 'react'
import logo from '../assets/logo_cropped.png'

export default function Header() {
  return (
    <header>
      <div>
        <img src={logo} alt=''></img>
      </div>
    </header>
  )
}
